import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require('slick-carousel');
// require('masonry-layout');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

$(document).ready(function(){

  //Main navigation active parent menu link
  $(".active.is-submenu-item.is-dropdown-submenu-item").parent("ul").parent("li").addClass("active");

  // Initialize Slick Carousel, with configurations
  if($(".slick-slider").length) {
    $('.slick-slider').slick({
      // mobileFirst: true,
      arrows: true,
      // appendArrows: $('.slick-arrows'),
      // prevArrow: $('.prev'),
      // nextArrow: $('.next'),
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 300,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false
          }
        }
      ]
    });
    $('.pause').on('click', function() {
      $('.slick-slider')
        .slick('slickPause')
    });
  
    $('.play').on('click', function() {
      $('.slick-slider')
        .slick('slickPlay')
    });
  }


  /* ACTIVE PARENT MENU NAV SNIPPET */
  var loc = location;
  var endPath = loc.pathname;
  
  if(endPath != "/"){
    $('.main-nav ul.menu a').filter(function() {
      return this.href.indexOf(endPath) > -1;
    }).parents('li').addClass('active');

    $('.mobile-nav ul.menu a').filter(function() {
      return this.href.indexOf(endPath) > -1;
    }).parents('li').addClass('active');    
  }

  //Table to Foundation columns transformation
  var rowMarkup = "";
  
  $('table.columns').each(function( index ) {
    var table_classes = $(this).attr("class").split(' ');
    var border_class = '';
    for(var i = 0; i < table_classes.length; i++){
      if(table_classes[i] == "border-desktop" ||
      table_classes[i] == "border-mobile" ||
      table_classes[i] == "border-desktop-mobile" ||
      table_classes[i] == "border-shadow"){
        border_class = table_classes[i];
      }
    }
    if ($(this).hasClass( "table-grid" )) {
      $(this).find('td').replaceWith(function(){
        markup = '<div class="cell">' + $(this).html() +'</div>';
        return markup;
      });
    }else if ($(this).hasClass( "four" )) {
      $(this).find('td').replaceWith(function(){
        markup = '<div class="cell large-3 medium-6 small-12">' + $(this).html() +'</div>';
        return markup;
      });
    } else if ($(this).hasClass( "three" )) {
      $(this).find('td').replaceWith(function(){
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");
                
        if(typeof classes == 'undefined'){ classes = ''; }
        if(typeof styles == 'undefined'){ styles = ''; }
        
        markup = '<div class="cell large-4 medium-4 small-12 '+classes+'" style="'+styles+'">' + $(this).html() +'</div>';
        return markup;
      });
    } else if ($(this).hasClass( "two" )) {
      if($(this).hasClass("staff")){
        $(this).find('td:first-of-type').replaceWith(function(){
          var classes = $(this).attr("class");
          var styles = $(this).attr("style");
          if(typeof classes == 'undefined'){ classes = ''; }
          if(typeof styles == 'undefined'){ styles = ''; }
          
          var markup = '<div class="cell large-2 medium-3 small-12 large-offset-1 '+classes+'" style="'+styles+'">' + $(this).html() +'</div>';
          return markup;
        });  
        $(this).find('td:last-of-type').replaceWith(function(){
          var classes = $(this).attr("class");
          var styles = $(this).attr("style");
          if(typeof classes == 'undefined'){ classes = ''; }
          if(typeof styles == 'undefined'){ styles = ''; }
          
          var markup = '<div class="cell large-8 medium-9 small-12 '+classes+'" style="'+styles+'">' + $(this).html() +'</div>';
          return markup;
        });               
      }
      else{
        $(this).find('td').replaceWith(function(){
          var classes = $(this).attr("class");
          var styles = $(this).attr("style");
          if(typeof classes == 'undefined'){ classes = ''; }
          if(typeof styles == 'undefined'){ styles = ''; }
          
          var markup = '<div class="cell large-6 medium-6 small-12 '+classes+'" style="'+styles+'">' + $(this).html() +'</div>';
          return markup;
        });
      }
    } else if ($(this).hasClass( "one" )) {
      $(this).find('td').replaceWith(function(){
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");
        if(typeof classes == 'undefined'){ classes = ''; }
        if(typeof styles == 'undefined'){ styles = ''; }

        var markup = '<div class="cell small-12 '+classes+'" style="'+styles+'">' + $(this).html() +'</div>';
        return markup;
      });
    }
    else {
    $(this).find('td').replaceWith(function(){
      var classes = $(this).attr("class");
      var styles = $(this).attr("style");
      if(typeof classes == 'undefined'){ classes = ''; }
      if(typeof styles == 'undefined'){ styles = ''; }

      markup = '<div class="cell '+classes+'" style="'+styles+'">' + $(this).html() +'</div>';
      return markup;
    });
    }
    
    if ($(this).hasClass( "five" )) {
      $(this).find('tr').replaceWith(function(){
        rowMarkup = '<div class="grid-x grid-margin-x small-up-1 medium-up-5">' + $(this).html() +'</div>';
        return rowMarkup;
      });
      $(this).replaceWith(function(){
        wrapperMarkup = '<div class="tablegrid-wrapper '+border_class+'">' + $(this).html() +'</div>';
        return wrapperMarkup;
      });
    }
    else{
      $(this).find('tr').replaceWith(function(){
        rowMarkup = '<div class="grid-x grid-margin-x">' + $(this).html() +'</div>';
        return rowMarkup;
      });
      $(this).replaceWith(function(){
        wrapperMarkup = '<div class="tablegrid-wrapper '+border_class+'">' + $(this).html() +'</div>';
        return wrapperMarkup;
      });
    }

  });

  //Mobile Menu - Dropdown chevron click functionality that lets
  //users visit the top Accordion as a link, or expand accordion
  $(".sub-chev").on("click", function(e){
    e.stopPropagation();
  });

  //Masonry
    
  // $('.masonry-grid').masonry({
  //   // options...
  //   itemSelector: '.grid-item',
  //   masonry: {
  //     columnWidth: 200,
  //     horizontalOrder: true,
  //     gutter: 10
  //   }
  // });

});